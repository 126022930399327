// import { Link } from 'gatsby';
// import React from 'react';
// import styled from 'styled-components';
// import Span from '../../../../prolon-theme/components/general/dynamic_html';
// const StyledDiv = styled.div`
//   width: 100%;
//   min-width: 160px;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   transition: all 1s ease;
//   background: white;
//   height: 520px;
//   background-image: ${(props) => `url(${props.bgImage})`};
//     background-position: bottom center;
//     background-repeat: no-repeat;
//     background-size: cover;
//   @media (max-width: 767px) {
//     background-image: ${(props) => `url(${props.bgImageMobile})`};
   
//     height: 420px;
//   }
//   .gridItem {
//     position: relative;
//     text-align: left;
//     color: black;
//     background-color:red;
//   }
//   @media only screen and (max-width: 767px) {
//     .gridItem {
//       text-align: top;  
//     }
//     .content-block {
//       top: 0px;
//       left: 0px;
//     }
//   }
//   .image-div {
//     width: 100%;
//     max-width: 100%;
//   } 

//   .content-block {
//     background: transparent;
//     width: 100%;
//     max-width: 90%;
//     max-height: 90%;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-around;
//     align-items: center;
//   }

//   .offSale {
//     text-transform: uppercase;
//     letter-spacing: 0.3em;
//     font-size: 16px;
//     line-height: 1.2;
//     margin-bottom: 12px;
//     text-align: center;
//     font-family: 'Harmonia Sans', 'Nunito Sans', Arial, sans-serif;
//     color: #002500;
//     text-align: right;
//     margin-left: auto;
//   }
//   .afterSale {
//     font-size: 50px; 
//     font-family: 'Harmonia Sans', 'Nunito Sans', Arial, sans-serif;
//     font-weight: 600; 
//     color: #002500;
//     margin-bottom: 12px;    display: block;
//     text-align: right;
//     width: 100%;
//   }

//   .fasting {
//     font-size: 1.25em;
//     color: #002500;
//     text-align: right;
//     margin-bottom: 16px;
//     line-height: 1.6;
//     font-weight: 400;
//     font-family: 'Harmonia Sans', 'Nunito Sans', Arial, sans-serif;
//   } 
//   .orderNowButton {
//     background:var(--colorBtnPrimary);
//     border: none !important;
//     line-height: 1.42;
//     text-decoration: none;
//     text-align: center;
//     white-space: normal;
//     font-size: 16px;
//     font-weight: 700;
//     text-transform: uppercase;
//     letter-spacing: 0.2em;
//     display: inline-block;
//     padding: 11px 20px;
//     width: auto;
//     min-width: 90px;
//     cursor: pointer;
//     margin-left: auto;
//     font-size: 13px;
//     color:white;
//   }
//   .orderSpan {
//     color: #ffffff;
//     line-height: 1.42;
//     text-align: center;
//     white-space: normal;
//     font-size: 16px;
//     font-weight: 700;
//     text-transform: uppercase;
//     letter-spacing: 0.2em;
//   }

//   @media (max-width: 767px) {
//     .content-block{
//       margin:0 auto;    margin-top: -100px;

//     }
//     .orderSpan,
//     .offSale {
//       font-size: 14px;
//       text-align: center;
//       margin:0 auto;
//     }
//     .afterSale {
//       font-size: 2.25em;      text-align: center;
//       margin:0 auto;
 
//     }
//     .fasting {
//       text-align: center;
//       font-size: 0.85em;
//       margin:0 auto;
//       margin-bottom: 20px;
//     }
//     .orderNowButton{
//       font-size: 11px;
//       text-align: center;
//       margin:0 auto;
//     }
//   }
// `;

// const PromoGrid = ({data,page}) => {
   
//     let finalData= data?.schema_json;  
  
//       let content=  {
//           title:'New Year, Fresh Start',
//           subTitle:'A 5-DAY FAST, WITH FOOD!', 
//           description:`<div class="row" style="max-width: 670px; font-size: .9em; margin: auto auto 0 0px;">Kick start your health goals with a <strong>5-day fast with food! </strong>Scientifically formulated meal plan to provide nourishment while keeping your body in a fasting state. No guess work, no planning, a fresh way to fast.</div>`,
//           backgroundColor:'#f3fae8',
//           backgroundImage:'//cdn.shopify.com/s/files/1/0126/2921/3243/files/Valter_Longo-_Cream_9b676eb4-c264-4157-822a-ee4bbe8a00dc_1512x.png?v=1600302124', 
//           backgroundImageMobile:'https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ProLon_Mobile_Hero_-_Fresh_Start_1944x.png?v=1609962116', 
//           buttons:[
//             {
//               id: 1,
//               text: `ORDER NOW + FREE SHIPPING`,
//               href: '/products/buy-prolon', 
//               style:{
//                     backgroundColor: '#ff6353',
//                     textColor:'#fff'
//                     }
//             }
//           ], 
//         ...finalData
//       }
//   return (
//     <StyledDiv
//       bgImage={content?.backgroundImage}
//       bgImageMobile={content.backgroundImageMobile}
//     >
//       <div className="gridItem">
//         <div className="content-block">
//           <div className="offSale">
//             <Span html={content.subTitle} />
//           </div>
//           <div class="afterSale">
//             <Span html={content.title} />
//           </div>

//           <Span className="fasting" html={content.description} />

//           {content.buttons &&
//             content.buttons.map((e) => {
//               return (
//                 <Link to={e.href} class="orderNowButton" style={content.style}>
//                   {e.text} 
//                 </Link>
//               );
//             })}
//         </div>
//       </div>
//     </StyledDiv>
//   );
// };

// export default PromoGrid;


import React from "react";
import tw, {styled} from "twin.macro";

import { Link } from "../../../../@gatsbystorefront/.gatsby-theme-storefront-shopify/utils/general.js";


const Container = styled.div`
  ${tw`w-full bg-center bg-cover px-12 mt-8`}
`;

const TwoColumn = styled.div`
${tw`flex justify-between items-center flex-col lg:flex-row`}
background:url('//cdn.shopify.com/s/files/1/0126/2921/3243/files/ProLon_-_Day_Cream_71b7c4f5-63ef-407e-bcc3-2fc46b8d7bf6_1728x.png?v=1600302123');
${props=>props.custom_css}
`
const LeftColumn = styled.div`
${tw`px-5 flex flex-col items-center lg:block w-full lg:w-1/2 pt-8 lg:pt-8`}
`
const RightColumn = styled.div`
${tw`w-full lg:w-1/2 mt-8 lg:mt-0`}
img{
    width:100%;
}
` 

const Heading = styled.h1`
  ${tw`text-xl leading-none text-center text-primary-900 lg:text-left sm:text-2xl lg:text-3xl xl:text-4xl`} 
  font-weight:700;
   
  span {
    ${tw`inline-block mt-4`}
  }
`; 


const Paragraph = tw.p`my-4 text-center md:text-left text-sm xl:text-base text-primary-900 md:my-8 `;
 
const PrimaryAction = styled(Link)`
${tw`px-8 py-3 mx-1 mt-6 md:mt-10 text-sm font-medium transition duration-300 shadow sm:text-base sm:mt-1 sm:px-8 sm:py-3 hover:no-underline hocus:text-gray-100 focus:shadow-outline`}
background-color:${
  props=>props.backgroundColor
};
color:${
  props=>props.textColor
};
`
export default ({data,page}) => {
   
    let finalData= data?.schema_json;  
  
      let content=  {
          heading:'The Only Scientifically<br />Tested Fasting Diet®',
          description:`ProLon is the first and only clinically tested, doctor recommended fasting meal program. ProLon’s breakthrough nutri-technology was researched for over 20 years and developed by Dr. Longo at The Longevity Institute at the University of Southern California with tens of millions of dollars in funding support from the National Institutes of Health. In 2018, TIME nominated Dr. Longo as among the top 50 most influential people in health.`,
          background_color:'#f3fae8',
          background_image:'//cdn.shopify.com/s/files/1/0126/2921/3243/files/Valter_Longo-_Cream_9b676eb4-c264-4157-822a-ee4bbe8a00dc_300x.png?v=1600302124', 
          backgroundImageMobile:'https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ProLon_Mobile_Hero_-_Fresh_Start_1944x.png?v=1609962116', 
          buttons:[
            {
              id: 1,
              button_text: `ORDER NOW + FREE SHIPPING`,
              button_url: '/products/buy-prolon', 
              background_color: '#ff6353',
              text_color:'#fff'
        
            }
          ], 
        ...finalData
      }



return (
    <Container>
        <TwoColumn custom_css={content?.custom_css}>
          <LeftColumn>
            <Heading>
              <div dangerouslySetInnerHTML={{__html:content?.heading}}></div>
            </Heading>
    
            <Paragraph  dangerouslySetInnerHTML={{__html:content?.description}} ></Paragraph>
            {content.buttons &&
            content.buttons.map((button) => {
              return (

                <PrimaryAction
                href={button.open_new_tab ? button.button_url : null}
                to={button.open_new_tab ? null : button.button_url}
                target="_blank"
                backgroundColor={button.background_color}
                textColor={button.text_color}
                >
                  {button.button_text}
                </PrimaryAction>
                // <PrimaryAction to={e.href} class="orderNowButton" style={content.style}>
                //   {e.text} 
                // </PrimaryAction>
              );
            })}
          </LeftColumn>
          <RightColumn>
             <img src={content?.background_image}></img>
          </RightColumn>
        </TwoColumn>
    </Container>
  );
};
